import { memo, useState } from 'react';
import { RFPercentage } from '../../utils/Functions';
import { Box } from '@material-ui/core';
import Texto from './Texto';
import Boton from './Boton';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../Router/routes';

const BannerModalidad = memo(
  ({ item, onPress = null, disabled = false, height, width }) => {
    const [expanded, setExpanded] = useState(false);

    let history = useHistory();

    return (
      <Box
        style={{
          marginBottom: 5,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            height: '150px',
            borderRadius: 12,
            marginBottom: 12,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
          }}
        >
          <Box
            style={{
              height: '100%',
              width: '100%',
              borderRadius: 12,
              overflow: 'hidden',
              backgroundImage: `url(${item.imagen})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={() => setExpanded(!expanded)}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Texto
                numberOfLines={4}
                style={{
                  fontSize: RFPercentage(4.5),
                  fontWeight: '700',
                  maxWidth: '90%',
                  textAlign: 'center',
                  color: 'white',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                }}
              >
                {item.texto}
              </Texto>
            </Box>
          </Box>
        </Box>
        {expanded && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 12,
                width: '90%',
              }}
            >
              <Boton
                height={height * 0.2}
                width={width * 0.41}
                onPress={() =>
                  history.push(ROUTES.ligas, { modalidad: item.texto })
                }
                title={'Ligas'}
                titleSize={RFPercentage(2)}
              />
              <Boton
                height={height * 0.2}
                width={width * 0.41}
                title={'Copas'}
                titleSize={RFPercentage(2)}
                onPress={() =>
                  history.push(ROUTES.copas, { modalidad: item.texto })
                }
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 12,
                width: '90%',
              }}
            >
              <Boton
                height={height * 0.2}
                width={width * 0.41}
                onPress={() =>
                  history.push(ROUTES.concursos, {
                    modalidad: item.texto,
                    tipos: ['Clasificación', 'Final'],
                  })
                }
                title={'Concursos'}
                titleSize={RFPercentage(2)}
              />
              <Boton
                height={height * 0.2}
                width={width * 0.41}
                onPress={() =>
                  history.push(ROUTES.campeonatos, {
                    modalidad: item.texto,
                    tipos: ['Campeonato Regional', 'Campeonato de España'],
                  })
                }
                title={'Campeonatos'}
                titleSize={RFPercentage(2)}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

export default BannerModalidad;
