import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useUsuario } from '../../Context/UsuarioContext';
import { WebScreen } from './WebScreen.js';
import { Aplicacion } from './Aplicacion.js';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { ROUTES } from '../Router/routes.js';
import { useTema } from '../../Context/TemaContext.js';
import { Header } from './Header';
import { Loader } from '../Componentes/Loader.js';
import LigasMedios from './Medios/LigasMedios.js';
import Medios from './Medios/Medios.js';
import Tienda from './Tienda.js';
import TV from './TV/TV.js';
import { useData } from '../../Context/DataContext.js';
import { useConfig } from '../../Context/ConfigContext.js';
import Ligas from './Competiciones/Ligas/Ligas.js';
import Copas from './Competiciones/Copas/Copas.js';
import Concursos from './Competiciones/Concursos/Concurso.js';

const initializeAds = () => {
  if (window.adsbygoogle && window.adsbygoogle.push) {
    const insElements = document.querySelectorAll('ins.adsbygoogle');
    if (insElements.length === 0) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
};

export const Main = () => {
  const { loading, usuario } = useUsuario();
  const { temaLoading, tema } = useTema();
  const { datosLoading } = useData();
  const { setModalVisible } = useConfig();

  const location = useLocation();

  const [visibleUsuario, setVisibleUsuario] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    initializeAds();
  }, []);

  useEffect(() => {
    const loadAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('Error loading AdSense ads', e);
      }
    };

    const script = document.createElement('script');
    script.async = true;
    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5968796742183376';
    script.crossOrigin = 'anonymous';
    script.onload = loadAds;
    document.head.appendChild(script);
  }, []);

  const history = useHistory();

  function redirectToMobileApp(url) {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = url;
      setTimeout(() => {
        setModalVisible({
          children: <Tienda />,
        });
      }, 2000);
    } else if (/android/i.test(userAgent)) {
      window.location.href = url;
      setTimeout(() => {
        setModalVisible({
          children: <Tienda />,
        });
      }, 2000);
    } else {
      setModalVisible({
        children: <Tienda />,
      });
    }
  }

  useEffect(() => {
    const isFromLink = history.action === 'POP';

    if (isFromLink) {
      if (
        location.pathname.includes('tutorial') ||
        location.pathname.includes('partido') ||
        location.pathname.includes('concurso') ||
        location.pathname.includes('qrLogin')
      ) {
        try {
          redirectToMobileApp(`resultados-de-bolos:/${location.pathname}`);
        } catch (e) {}
      }
    }
  }, [history, location]);

  const Anuncio = () => {
    return (
      <Box
        style={{
          flex: 1,
        }}
      >
        <ins
          style={{ display: 'flex', height: '100%' }}
          data-ad-client='ca-pub-5968796742183376'
          data-ad-slot='7309751360'
          data-ad-format='auto'
          data-full-width-responsive='true'
        ></ins>
      </Box>
    );
  };

  if (
    loading ||
    temaLoading ||
    (datosLoading && usuario.nombreUsuario !== 'Anónimo')
  ) {
    return (
      <Box
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      w={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: tema.backgroundColor,
      }}
    >
      <Header
        visible={visible}
        setVisible={setVisible}
        visibleUsuario={visibleUsuario}
        setVisibleUsuario={setVisibleUsuario}
      />
      <Box
        sx={{
          flexDirection: { xs: 'column', md: 'column', lg: 'row' },
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {location.pathname !== ROUTES.home && (
          <Box
            sx={{
              height: '100%',
              width: '15%',
              display: { xs: 'none', md: 'none', lg: 'flex' },
            }}
          >
            <Anuncio />
          </Box>
        )}
        <Box
          sx={{
            maxWidth: { xs: '100vw', md: '100vw', lg: 'Calc(100vw - 500px)' },
            flex: 1,
          }}
        >
          <Switch>
            <Route path={ROUTES.home} exact>
              <WebScreen
                visible={visibleUsuario}
                setVisible={setVisibleUsuario}
              />
            </Route>
            <Route path={ROUTES.inicio} exact>
              <Aplicacion />
            </Route>
            <Route path={ROUTES.tv} exact>
              <TV />
            </Route>
            <Route path={ROUTES.medios} exact>
              <Medios />
            </Route>
            <Route path={ROUTES.ligasMedios} exact>
              <LigasMedios />
            </Route>
            <Route path={ROUTES.ligas} exact>
              <Ligas />
            </Route>
            <Route path={ROUTES.copas} exact>
              <Copas />
            </Route>
            <Route path={ROUTES.concursos} exact>
              <Concursos />
            </Route>
            <Route path={ROUTES.campeonatos} exact>
              <Concursos />
            </Route>
            <Route path={'/'}>
              <Redirect to={ROUTES.home} />
            </Route>
            <Redirect to={ROUTES.home} />
          </Switch>
        </Box>
        <Box
          sx={{
            height: { xs: '12vh', md: '12vh', lg: '100%' },
            width: { xs: '100%', md: '100%', lg: '15%' },
            maxHeight: { xs: '12vh', md: '12vh', lg: '100%' },
            maxWidth: { xs: '100%', md: '100%', lg: '15%' },
            display: 'flex',
          }}
        >
          <Anuncio />
        </Box>
      </Box>
    </Box>
  );
};
