import React from 'react';
import { useData } from '../../Context/DataContext.js';
import { Avatar, Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import IconoPeña from './IconoPena.js';
import { useMemo } from 'react';

const IconoJugador = (props) => {
  const { jugadores } = useData();

  const {
    jugador,
    size = 'pequeño',
    peña = true,
    jugadoresArray = jugadores !== null && jugadores !== undefined
      ? jugadores
      : [],
    paddingHorizontal = 5,
    style = null,
    borderRadius = 60,
  } = props;

  const jugadorData = useMemo(
    () =>
      jugadoresArray.find((item) => item.id === jugador) !== undefined
        ? jugadoresArray.find((item) => item.id === jugador)
        : null,
    [jugadoresArray, jugador]
  );

  if (jugador === '') {
    return <Box style={{ width: 40 }}></Box>;
  } else {
    return (
      <Box
        style={{
          ...style,
          paddingHorizontal: paddingHorizontal,
          position: 'relative',
          zIndex: 1,
        }}
      >
        {jugadorData !== null && jugadorData.imagen !== undefined ? (
          <img
            alt='jugador'
            src={jugadorData.imagen}
            style={{
              height:
                size === 'grande'
                  ? 120
                  : size === 'mediano'
                  ? 50
                  : size === 'pequeño'
                  ? 36
                  : 18,
              width:
                size === 'grande'
                  ? 120
                  : size === 'mediano'
                  ? 50
                  : size === 'pequeño'
                  ? 36
                  : 18,
              borderRadius: borderRadius,
            }}
          />
        ) : (
          <Avatar
            style={{
              height:
                size === 'grande'
                  ? 120
                  : size === 'mediano'
                  ? 50
                  : size === 'pequeño'
                  ? 36
                  : 18,
              width:
                size === 'grande'
                  ? 120
                  : size === 'mediano'
                  ? 50
                  : size === 'pequeño'
                  ? 36
                  : 18,
              marginBottom: 5,
            }}
          >
            <PersonIcon />
          </Avatar>
        )}
        {peña && (
          <IconoPeña
            peñaId={jugadorData.peña}
            size={
              size === 'grande'
                ? 50
                : size === 'mediano'
                ? 20
                : size === 'pequeño'
                ? 20
                : 12
            }
            style={{
              position: 'absolute',
              zIndex: 10,
              bottom: 0,
              right: 0,
              backgroundColor: 'white',
              borderWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: 60,
            }}
          />
        )}
      </Box>
    );
  }
};

export default IconoJugador;
