import React, { useMemo, useState } from 'react';
import { Box, Divider, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useData } from '../../Context/DataContext.js';
import { useTema } from '../../Context/TemaContext.js';
import IconoPeña from '../Componentes/IconoPena.js';
import Texto from '../Componentes/Texto.js';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CuadroDeTexto } from '../Componentes/CuadroDeTexto.js';
import { RFPercentage } from '../../utils/Functions.js';

export const Equipos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  // const [loading, setLoading] = useState(true);

  const { peñas, buscarLiga, ligas } = useData();
  const { tema } = useTema();
  const [arrayBuscador, setArrayBuscador] = useState(peñas);
  const [search, setSearch] = useState('');
  const [modalidad, setModalidad] = useState([]);
  const [activa, setActiva] = useState('Activa');
  const [clasico, setClasico] = useState(null);

  const [liga, setLiga] = useState([]);

  const updateSearch = (text) => {
    const array = [];
    peñas.forEach((equipo) => {
      const nombre = equipo.nombre.toUpperCase();
      const nombreClasico = equipo.nombreClasico
        ? equipo.nombreClasico.toUpperCase()
        : null;

      if (
        nombre.includes(text.toUpperCase()) ||
        (nombreClasico && nombreClasico.includes(text.toUpperCase()))
      ) {
        array.push(equipo);
      }
    });
    setArrayBuscador(array);
    setSearch(text);
  };

  const data = useMemo(() => {
    return arrayBuscador
      ? arrayBuscador
          .filter((peña) => {
            let bool = true;
            if (modalidad.length > 0 && !modalidad.includes(peña.modalidad)) {
              bool = false;
            }
            if (liga.length > 0 && !liga.includes(peña.liga)) {
              bool = false;
            }
            if (
              peña.activa !== undefined &&
              ((peña.activa === true && activa === 'Inactiva') ||
                (peña.activa === false && activa === 'Activa'))
            ) {
              bool = false;
            }
            if (
              (peña.clasico === undefined && clasico === 'Si') ||
              (peña.clasico === false && clasico === 'Si')
            ) {
              bool = false;
            }
            return bool;
          })
          .sort((a, b) => {
            if (a.nombre > b.nombre) {
              return 1;
            } else if (a.nombre < b.nombre) {
              return -1;
            } else {
              return 0;
            }
          })
      : [];
  }, [arrayBuscador, modalidad, activa, clasico, liga]);

  const Row = ({ index, style }) => {
    const item = data[index];

    if (item) {
      return (
        <div
          style={style}
          key={item.id}
          onClick={() => {
            navigator.clipboard.writeText(item.id);
          }}
        >
          <Box style={{ display: 'flex', flexDirection: 'row', minHeight: 80 }}>
            <Box
              style={{
                paddingLeft: 10,
                flex: 1,
                paddingBottom: 5,
                paddingTop: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <IconoPeña
                peñaId={item.id}
                size={65}
                disabled
                clasico={clasico === 'Si'}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 8,
                paddingLeft: 10,
              }}
            >
              <Texto
                style={{ fontSize: RFPercentage(2), fontWeight: '500' }}
                numberOfLines={1}
              >
                {clasico === 'Si' ? item.nombreClasico : item.nombre}
              </Texto>
              <Texto
                style={{
                  fontSize: RFPercentage(1.4),
                  fontWeight: '200',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
                numberOfLines={1}
              >
                {`${item.modalidad} ${item.liga && '/'} ${
                  buscarLiga(item.liga)?.nombre || ''
                }`}
              </Texto>
            </Box>
          </Box>
          <Divider />
        </div>
      );
    } else {
      return <div style={{ flex: 8, ...style }} key={Math.random()}></div>;
    }
  };

  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: tema.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          paddingTop: 12,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box style={{ width: '95%' }}>
          <CuadroDeTexto
            label='Buscar...'
            variant='outlined'
            value={search}
            onChange={updateSearch}
            fullWidth
            debounceable
          />
        </Box>
      </Box>
      <Box
        style={{
          flex: '1 1 auto',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={data.length}
              itemSize={80}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};
