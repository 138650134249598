import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ScrollRevealComponent from '../ScrollRevealComponent.js';
import AnimacionTexto from '../AnimacionTexto.js';
import { useTheme } from '@mui/material/styles';
import { useTema } from '../../Context/TemaContext.js';
import { ROUTES } from '../Router/routes.js';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

function MyObject() {
  const mesh = useRef();
  const mouseX = useRef(0);
  const mouseY = useRef(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      mouseX.current = event.clientX - window.innerWidth / 2;
      mouseY.current = event.clientY - window.innerHeight / 2;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useFrame(() => {
    if (mesh.current && mesh.current.rotation) {
      mesh.current.rotation.y = (mouseX.current / window.innerWidth) * 0.6;
      mesh.current.rotation.x = (mouseY.current / window.innerHeight) * 0.6;
    }
  });

  return (
    <mesh ref={mesh}>
      <boxGeometry />
      <meshStandardMaterial color='hotpink' />
    </mesh>
  );
}

export const WebScreen = ({ visible, setVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const { tema } = useTema();
  const { usuario } = useUsuario();

  let history = useHistory();

  return (
    <Box
      h={1}
      w={1}
      style={{
        width: '100%',
        display: 'flex',
        position: 'absolute',
        left: 0,
        backgroundColor: tema.backgroundColor,
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          zIndex: 1000000,
        }}
      >
        <Canvas>
          <OrbitControls />
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 15, 10]} angle={0.3} />
          <MyObject />
        </Canvas>
      </div> */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundSize: 'cover',
          overflowY: 'auto',
          left: 0,
          backgroundColor: 'transparent',
        }}
      >
        <Grid container style={{ width: '100%' }}>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 'Calc(100vh - 70px)',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <img
                src={require('../../assets/icono.png')}
                style={{
                  height: '25vh',
                  width: '25vh',
                  marginBottom: 20,
                  borderRadius: 24,
                }}
                alt='LOGO'
              />
              <Typography
                style={{
                  fontSize: '5vw',
                  fontWeight: '600',
                  color: tema.colors[500],
                  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  width: '80%',
                }}
              >
                RESULTADOS DE BOLOS
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '90%',
                alignSelf: 'center',
                marginBottom: 24,
              }}
            >
              <a
                href='https://apps.apple.com/es/app/resultados-de-bolos/id1610548244'
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  minHeight: '80px',
                  textDecoration: 'none',
                  maxWidth: '45vw',
                }}
              >
                <Paper
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    alignItems: 'center',
                    backgroundColor: 'black',
                    borderRadius: 12,
                  }}
                >
                  <img
                    src={require('../../assets/appStore.png')}
                    style={{
                      height: '60px',
                      width: '60px',
                      margin: 10,
                    }}
                    alt='AppStore'
                  />
                  <Box
                    sx={{
                      flex: 1,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: '400',
                        fontSize: isMobile ? '3vw' : '0.9vw',
                        color: 'white',
                      }}
                    >
                      Disponible en
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: isMobile ? '3vw' : '1.3vw',
                        color: 'white',
                      }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </Paper>
              </a>
              <a
                href={`https://play.google.com/store/apps/details?id=com.saro.marcos&hl=es_PY&gl=US`}
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  minHeight: '80px',
                  textDecoration: 'none',
                  maxWidth: '45vw',
                }}
              >
                <Paper
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    alignItems: 'center',
                    backgroundColor: 'black',
                    borderRadius: 12,
                  }}
                >
                  <img
                    src={require('../../assets/googlePlay.png')}
                    style={{
                      height: '60px',
                      width: '60px',
                      margin: 10,
                    }}
                    alt='AppStore'
                  />
                  <Box
                    sx={{
                      flex: 1,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: '400',
                        fontSize: isMobile ? '3vw' : '0.9vw',
                        color: 'white',
                      }}
                    >
                      Disponible en
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: isMobile ? '3vw' : '1.3vw',
                        color: 'white',
                      }}
                    >
                      Google Play
                    </Typography>
                  </Box>
                </Paper>
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: isMobile ? '50vh' : 'Calc(100vh - 70px)',
            }}
          >
            <ScrollRevealComponent>
              <>
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <AnimacionTexto
                    style={{
                      fontSize: isMobile ? '6vw' : '2vw',
                      fontWeight: '600',
                      color: '#555555',
                      textShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
                      textAlign: 'center',
                      width: '90%',
                    }}
                  />
                  <Button
                    size='large'
                    variant='contained'
                    onClick={() => {
                      history.push(ROUTES.inicio);
                    }}
                    style={{
                      fontSize: isMobile ? '5vw' : '2vw',
                      fontWeight: '600',
                      borderRadius: 60,
                      backgroundColor: tema.colors[500],
                      marginTop: 24,
                      color: '#fff',
                    }}
                  >
                    Aplicación
                  </Button>
                </Box>
              </>
            </ScrollRevealComponent>
          </Grid>
          {/* <ScrollRevealComponent>
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 48,
                marginBottom: 48,
              }}
            >
              <Typography
                style={{
                  fontSize: isMobile ? '6vw' : '3vw',
                  fontWeight: '600',
                  color: tema.texto,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                NUEVA WEB
              </Typography>
              <Typography
                style={{
                  fontSize: isMobile ? '4vw' : '2vw',
                  fontWeight: '600',
                  color: tema.texto,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                ¡¡¡Ahora los bolos están disponibles en todos los
                dispositivos!!!
              </Typography>
            </Grid>
          </ScrollRevealComponent>
          <ScrollRevealComponent>
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={require('../../assets/dispositivos.png')}
                style={{
                  height: '70vh',
                  maxWidth: '80vw',
                  borderRadius: 24,
                  objectFit: 'contain',
                }}
                alt='dispositivos'
              />
            </Grid>
          </ScrollRevealComponent>
          <ScrollRevealComponent>
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={8}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: isMobile ? '6vw' : '3vw',
                      fontWeight: '600',
                      color: tema.texto,
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    Web en desarrollo
                  </Typography>
                  <Typography
                    style={{
                      fontSize: isMobile ? '4vw' : '2vw',
                      fontWeight: '500',
                      color: tema.texto,
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    La web sigue en desarrollo y solo estará disponible para
                    algunos usuarios por el momento.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={require('../../assets/obras.png')}
                    style={{
                      height: '30vh',
                      width: '30vh',
                      borderRadius: 24,
                    }}
                    alt='LOGO'
                  />
                </Grid>
              </Grid>
            </Grid>
          </ScrollRevealComponent> */}
        </Grid>
      </Box>
    </Box>
  );
};
