import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, LinearProgress } from '@material-ui/core';
import { useUsuario } from '../../../Context/UsuarioContext';
import { useData } from '../../../Context/DataContext';
import { useCallback, useEffect, useState } from 'react';
import { useTema } from '../../../Context/TemaContext';
import { getFilterData } from '../../../database/firebase.js';
import Fichaje from './Tipos/Fichaje.js';
import Campeonato from './Tipos/Campeonato.js';
import Podcast from './Tipos/Podcast.js';
import Actualizacion from './Tipos/Actualizacion.js';
import Boton from '../../Componentes/Boton.js';
import Video from './Tipos/Video.js';
import Noticia from './Tipos/Noticia.js';

const Noticias = ({ navigation }) => {
  const { usuario } = useUsuario();
  const { información } = useData();

  const [noticias, setNoticias] = useState([]);
  const { tema } = useTema();

  const [filter, setFilter] = useState('Noticia');
  const [filterFichajes, setFilterFichajes] = useState('Rumores');

  const [indexSeleccionado, setIndexSeleccionado] = useState(null);

  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState(20);

  const filters = [
    {
      name: 'Noticia',
      icon: 'newspaper',
    },
    {
      name: 'Venta',
      icon: 'shopping-outline',
    },
    {
      name: 'Video',
      icon: 'youtube',
    },
    {
      name: 'Podcast',
      icon: 'podcast',
    },
    {
      name: 'Fichaje',
      icon: 'swap-horizontal',
    },
    {
      name: 'Campeonato',
      icon: 'trophy',
    },
    {
      name: 'Encuesta',
      icon: 'chart-bar',
    },
    {
      name: 'Actualización',
      icon: 'update',
    },
  ];

  const cargarNoticias = async (l) => {
    const filtro = [{ type: 'limit', value: l }];

    if (filter === 'Todo') {
      filtro.push({ type: 'orderBy', param: 'fecha', order: 'desc' });
    } else if (filter === 'Fichaje') {
      filtro.push({
        type: 'where',
        param: 'tipo',
        condition: '==',
        value: filter,
      });
      if (filterFichajes === 'Rumores') {
        filtro.push({
          type: 'where',
          param: 'estado',
          condition: '==',
          value: 'Pendiente',
        });
      } else if (filterFichajes === 'Verificados') {
        filtro.push({
          type: 'where',
          param: 'estado',
          condition: '==',
          value: 'Verificado',
        });
      } else {
        filtro.push({
          type: 'where',
          param: 'estado',
          condition: '==',
          value: 'Rechazado',
        });
      }
    } else {
      filtro.push({ type: 'orderBy', param: 'fecha', order: 'desc' });
      filtro.push({
        type: 'where',
        param: 'tipo',
        condition: '==',
        value: filter,
      });
    }

    const noticiasData = await getFilterData('noticias', filtro);

    const filtroFijados = [
      ...filtro,
      {
        type: 'where',
        param: 'fijado',
        condition: '==',
        value: true,
      },
    ];

    setNoticias(
      filter === 'Fichaje'
        ? noticiasData.sort(function (a, b) {
            if (a.nVotos < b.nVotos) {
              return 1;
            }
            if (a.nVotos > b.nVotos) {
              return -1;
            }
            return 0;
          })
        : noticiasData
    );
    setLoading(false);
  };

  useEffect(() => {
    if (información) {
      cargarNoticias(limit);
    }
  }, [filter, filterFichajes, limit, información]);

  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: tema.backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 12,
      }}
    >
      {/* <Box
        style={{
          backgroundColor: tema.backgroundColor,
          paddingTop: 2,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'auto',
        }}
      >
        <Box
          onClick={() => {
            setLoading(true);
            setLimit(10);
            setFilter('Todo');
          }}
          activeOpacity={0.6}
          style={{
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderBottomColor:
              filter === 'Todo' ? 'rgba(33, 176, 60, 0.7)' : 'transparent',
            borderBottomWidth: 2,
            margin: 5,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Texto
            style={{
              fontWeight: '500',
              fontSize: 15,
            }}
          >
            Todo
          </Texto>
        </Box>
        {filters.map((item, index) => {
          return (
            <Box
              key={index}
              onClick={() => {
                setLoading(true);
                setLimit(10);
                setFilter(item.name);
              }}
              activeOpacity={0.6}
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderBottomColor:
                  filter === item.name
                    ? 'rgba(33, 176, 60, 0.7)'
                    : 'transparent',
                borderBottomWidth: 2,
                margin: 5,
                marginBottom: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
               <IconButton
                    name={item.icon}
                    type='material-community'
                    size={16}
                  />
              <Texto
                style={{
                  fontWeight: '500',
                  fontSize: 15,
                  paddingLeft: 5,
                }}
              >
                {item.name}
              </Texto>
            </Box>
          );
        })}
      </Box> */}
      {/* {filter === 'Fichaje' && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingTop: 5,
            marginBottom: 5,
          }}
        >
          <Box
            underlayColor='white'
            onClick={() => {
              setLoading(true);
              setFilterFichajes('Rumores');
            }}
          >
            <Box
              style={{
                width: innerWidth / 3.5,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                backgroundColor:
                  filterFichajes === 'Rumores' ? 'rgb(192,192,192)' : 'white',
              }}
            >
              <Texto>Rumores</Texto>
            </Box>
          </Box>
          <Box
            underlayColor='white'
            onClick={() => {
              setLoading(true);
              setFilterFichajes('Verificados');
            }}
          >
            <Box
              style={{
                width: innerWidth / 3.5,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                backgroundColor:
                  filterFichajes === 'Verificados'
                    ? 'rgb(192,192,192)'
                    : 'white',
              }}
            >
               <IconButton
                name={'check-decagram'}
                type='material-community'
                color={'#1DA1F2'}
                size={25}
              />
            </Box>
          </Box>
          <Box
            underlayColor='white'
            onClick={() => {
              setLoading(true);
              setFilterFichajes('Rechazados');
            }}
          >
            <Box
              style={{
                width: innerWidth / 3.5,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                backgroundColor:
                  filterFichajes === 'Rechazados'
                    ? 'rgb(192,192,192)'
                    : 'white',
              }}
            >
              <IconButton
                name={'close'}
                type='material-community'
                color={'red'}
                size={25}
              />
            </Box>
          </Box>
        </Box>
      )} */}
      {loading ? (
        <LinearProgress
          color={'secondary'}
          style={{ marginTop: 6, backgroundColor: tema.backgroundColor }}
        />
      ) : (
        <Box style={{ height: 10 }} />
      )}
      <Box
        style={{
          flex: '1 1 auto',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Box
              style={{
                height: height,
                maxHeight: height,
                width: width,
                overflowY: 'auto',
              }}
            >
              {noticias.map((item) => {
                if (item.tipo === 'Podcast') {
                  return (
                    <Podcast
                      key={item.id}
                      item={item}
                      indexSeleccionado={indexSeleccionado}
                      setIndexSeleccionado={setIndexSeleccionado}
                      border
                    />
                  );
                } else if (item.tipo === 'Video') {
                  return (
                    <Video
                      key={item.id}
                      item={item}
                      indexSeleccionado={indexSeleccionado}
                      setIndexSeleccionado={setIndexSeleccionado}
                    />
                  );
                } else if (item.tipo === 'Fichaje') {
                  return <Fichaje key={item.id} item={item} border />;
                } else if (item.tipo === 'Noticia') {
                  return (
                    <Noticia
                      key={item.id}
                      item={item}
                      indexSeleccionado={indexSeleccionado}
                      setIndexSeleccionado={setIndexSeleccionado}
                    />
                  );
                  {
                    /* } else if (item.tipo === 'Venta') {
                  return (
                    <Venta
                      key={item.id}
                      item={item}
                      indexSeleccionado={indexSeleccionado}
                      setIndexSeleccionado={setIndexSeleccionado}
                    />
                  ); */
                  }
                } else if (item.tipo === 'Campeonato') {
                  return <Campeonato key={item.id} item={item} border />;
                  {
                    /* } else if (item.tipo === 'Encuesta') {
                  return (
                    <Encuesta
                      key={item.id}
                      indexSeleccionado={indexSeleccionado}
                      setIndexSeleccionado={setIndexSeleccionado}
                      item={item}
                      refresh={() => cargarNoticias(limit)}
                    />
                  ); */
                  }
                  {
                    /* } else if (item.tipo === 'Actualización') {
                  return <Actualizacion key={item.id} item={item} />; */
                  }
                  {
                    /* } else if (item.tipo === 'Navidad') {
                  return <Navidad key={item.id} item={item} />; */
                  }
                } else {
                  return <></>;
                }
              })}
              {noticias.length > 0 && limit % 20 === 0 && (
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Boton
                    title={'...'}
                    width={200}
                    onPress={() => {
                      setLoading(true);
                      setLimit((prevState) => prevState + 20);
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default Noticias;
