import React, { useMemo } from 'react';
import { useTema } from '../../Context/TemaContext.js';
import { useData } from '../../Context/DataContext.js';
import { Box } from '@material-ui/core';

const IconoPeña = (props) => {
  const {
    peñaId,
    size = 60,
    style = {},
    tintColor,
    clasico = false,
    backgroundColor,
  } = props;

  const { buscarPeña, peñas } = useData();
  const { tema } = useTema();

  const peña = useMemo(() => buscarPeña(peñaId), [peñaId, peñas]);

  return (
    <Box style={style}>
      <Box
        style={{
          backgroundColor: backgroundColor
            ? backgroundColor
            : tema.id === 'Oscuro'
            ? 'white'
            : 'transparent',
          borderRadius: 60,
          width: size,
          height: size,
        }}
      >
        <img
          alt='escudo'
          style={{
            width: size,
            height: size,
            ...(tintColor && { tintColor: tintColor }),
          }}
          src={
            peña && peña.escudo
              ? `${clasico ? peña.escudoClasico : peña.escudo}?refreshKey=${
                  peña.lastUpdate
                }`
              : require('../../assets/escudos/escudo.webp')
          }
        />
      </Box>
    </Box>
  );
};

export default IconoPeña;
