import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import { getRef } from '../../database/firebase.js';
import moment from 'moment/moment.js';
import { useData } from '../../Context/DataContext.js';
import { onSnapshot } from 'firebase/firestore';
import { useConfig } from '../../Context/ConfigContext.js';
import Texto from '../Componentes/Texto.js';
import PartidoComponente from '../Componentes/PartidoComponente.js';
import Fechas from '../Componentes/Fechas.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import ConcursoComponente from '../Componentes/ConcursoComponente.js';
import HeaderItem from './HeaderItem.js';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { hashCode } from '../../utils/Functions.js';
import { useTema } from '../../Context/TemaContext.js';

export const PartidosInicio = () => {
  const {
    datosLoading,
    ligas,
    buscarCompeticion,
    buscarConcurso,
    categorias,
    categoriasFix,
    jugadores,
  } = useData();
  const { filtro } = useConfig();
  const { usuario } = useUsuario();
  const { tema } = useTema();

  const [partidos, setPartidos] = useState([]);
  const [tiradas, setTiradas] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [filter, setFilter] = useState(filtro);
  const [filtroVisible, setFiltroVisible] = useState(false);

  const [loadingPartidos, setLoadingPartidos] = useState(true);
  const [loadingTiradas, setLoadingTiradas] = useState(true);

  const suscribePartidos = async (fecha) => {
    const partidosRef = await getRef('partidos', [
      {
        type: 'where',
        param: 'fecha',
        condition: '==',
        value: fecha,
      },
      { type: 'orderBy', param: 'hora' },
    ]);

    const unsub = onSnapshot(partidosRef, async (docsSnap) => {
      const partidosArray = docsSnap.docs.map((doc) => {
        const data = doc.data();
        const liga = buscarCompeticion(data.liga);

        return {
          ...data,
          id: doc.id,
          orden: liga?.orden || hashCode(liga.id) * 10000,
          competicion: liga,
        };
      });

      const partidosOrdenados = partidosArray.sort((a, b) => {
        if (a.orden !== b.orden) {
          return a.orden - b.orden;
        } else {
          return a.hora - b.hora;
        }
      });

      const partidosConHeaders = [];

      partidosOrdenados.forEach((item, index) => {
        if (
          index === 0 ||
          (item.liga && partidosOrdenados[index - 1].liga !== item.liga)
        ) {
          const competicion = item.competicion;
          const titulo =
            filter && filter.modalidades && filter.modalidades.length === 1
              ? `${competicion.nombre || ''}`
              : `${competicion.nombre || ''} ${
                  competicion.clasificacion ? '-' : ''
                } ${
                  competicion && competicion.clasificacion
                    ? competicion.modalidad
                    : ''
                }`;

          partidosConHeaders.push({
            id: item.liga,
            isHeader: true,
            liga: competicion,
            titulo: titulo,
          });
        }
        partidosConHeaders.push(item);
      });

      setPartidos(partidosConHeaders);
      setLoadingPartidos(false);
    });

    return unsub;
  };

  const suscribeConcursos = async (fecha) => {
    const tiradasRef = await getRef('tiradas', [
      {
        type: 'where',
        param: 'fecha',
        condition: '==',
        value: fecha,
      },
      { type: 'orderBy', param: 'hora' },
    ]);

    const unsub = onSnapshot(tiradasRef, async (docsSnap) => {
      if (docsSnap) {
        const tiradasArray = docsSnap.docs.map((doc) => {
          const data = doc.data();
          const concurso = buscarConcurso(data.concursoId);
          return {
            ...data,
            id: doc.id,
            orden:
              concurso.categorías && concurso.categorías[0]
                ? categorias.find((i) => i.id === concurso.categorías[0])?.orden
                : 1000000,
            concurso: concurso,
          };
        });

        const tiradasOrdenadas = tiradasArray
          .filter((i) => i.concursoId !== 'El Corro')
          .sort((a, b) => {
            if (a.orden !== b.orden) {
              return a.orden - b.orden;
            } else if (a.concursoId !== b.concursoId) {
              return a.concursoId < b.concursoId
                ? -1
                : a.concursoId > b.concursoId
                ? 1
                : 0;
            } else {
              return a.hora - b.hora;
            }
          });

        const tiradasConHeaders = [];

        tiradasOrdenadas.forEach((item, index) => {
          if (
            index === 0 ||
            (item.concursoId &&
              tiradasOrdenadas[index - 1].concursoId !== item.concursoId)
          ) {
            const concurso = item.concurso;
            const titulo =
              concurso.tipo === 'Final'
                ? `Final - ${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : concurso.tipo === 'Clasificacion'
                ? `${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : `${concurso.tipo} ${
                    concurso.individual ? 'Individual' : 'por parejas'
                  } ${categoriasFix(concurso.categorías)} - ${
                    concurso.modalidad
                  }`;

            tiradasConHeaders.push({
              id: item.concursoId,
              isHeader: true,
              concurso: concurso,
              titulo: titulo,
            });
          }
          tiradasConHeaders.push(item);
        });

        setTiradas(tiradasConHeaders);
        setLoadingTiradas(false);
      }
    });

    return unsub;
  };

  useEffect(() => {
    if (!datosLoading && fechaSeleccionada) {
      suscribeConcursos(fechaSeleccionada);
      suscribePartidos(fechaSeleccionada);
    }
  }, [fechaSeleccionada, datosLoading]);

  const renderItem = useCallback((item, index) => {
    if (item.isHeader) {
      return <HeaderItem key={index} item={item} />;
    } else if (item.ch_l !== undefined) {
      return <PartidoComponente key={index} partido={item} />;
    } else {
      return (
        <ConcursoComponente
          key={index}
          tirada={item}
          concurso={item.concurso}
          route={'Drawer'}
        />
      );
    }
  }, []);

  const flatData = useMemo(() => {
    const {
      enDirecto,
      televisado,
      modalidades,
      ligas: filterLigas,
      copas,
      peñas,
      tipoConcurso,
      categorias,
      jugadores: filterJugadores,
    } = filter;

    const partidosOrdenados = partidos.filter((partido) => {
      const l = ligas.find((i) => i.id === partido.liga) || {};

      return (
        partido.isHeader ||
        ((!enDirecto ||
          (partido.estado === 'Sin jugar' &&
            ((moment().format('YYYY-MM-DD') === partido.fecha &&
              moment().format('HH:mm') >= partido.hora) ||
              moment().format('YYYY-MM-DD') > partido.fecha))) &&
          (!televisado || partido.televisiones.length > 0) &&
          (modalidades.length === 0 ||
            (l.modalidad && modalidades.includes(l.modalidad))) &&
          (filterLigas.length === 0 ||
            l.tipo !== 'liga' ||
            filterLigas.includes(partido.liga)) &&
          (!copas ||
            copas.length === 0 ||
            l.tipo !== 'copa' ||
            (copas && copas.includes(partido.liga))) &&
          (peñas.length === 0 ||
            peñas.includes(partido.local) ||
            peñas.includes(partido.visitante)))
      );
    });

    const tiradasOrdenadas = tiradas.filter((tirada) => {
      const c = tirada.concurso;
      const jugador = jugadores.find(
        (i) => i.usuario === usuario.nombreUsuario
      );

      return (
        tirada.isHeader ||
        (tirada.concursoId !== 'El Corro' &&
          ((jugador &&
            c.categorías &&
            c.categorías.some((item) => jugador.categorías.includes(item))) ||
            (tirada.jugadores1 !== undefined &&
              (tirada.jugadores1[0] !== '' || tirada.jugadores2[0] !== '')) ||
            (tirada.jugador1 !== undefined &&
              (tirada.jugador1 !== '' || tirada.jugador2 !== ''))) &&
          (!televisado || (c.televisiones && c.televisiones.length > 0)) &&
          (!tipoConcurso ||
            tipoConcurso.length === 0 ||
            tipoConcurso.includes(c.tipo)) &&
          (modalidades.length === 0 ||
            (c.modalidad && modalidades.includes(c.modalidad))) &&
          (!categorias ||
            categorias.length === 0 ||
            (c.categorías &&
              categorias.some((i) => c.categorías.includes(i)))) &&
          (!filterJugadores ||
            filterJugadores.length === 0 ||
            filterJugadores.includes(tirada.jugador1 || tirada.jugadores1) ||
            filterJugadores.includes(tirada.jugador2 || tirada.jugadores2)))
      );
    });

    return loadingPartidos || loadingTiradas
      ? []
      : [
          ...partidosOrdenados,
          ...tiradasOrdenadas.filter(
            (tirada) =>
              !tirada.isHeader ||
              tiradasOrdenadas.filter((i) => i.concursoId === tirada.id)
                .length > 0
          ),
        ];
  }, [
    partidos,
    tiradas,
    filter,
    fechaSeleccionada,
    loadingPartidos,
    loadingTiradas,
  ]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box style={{ marginLeft: 12, marginRight: 12 }}>
        <Fechas
          setLoading={() => {
            setLoadingPartidos(true);
            // setLoadingTiradas(true);
          }}
          fechaSeleccionada={fechaSeleccionada}
          setFechaSeleccionada={setFechaSeleccionada}
          loading={loadingPartidos || loadingTiradas}
        />
      </Box>

      {loadingPartidos || loadingTiradas ? (
        <LinearProgress
          color={'secondary'}
          style={{ backgroundColor: tema.backgroundColor }}
        />
      ) : (
        <Box style={{ flex: 1 }}>
          <AutoSizer>
            {({ height, width }) => (
              <Box
                style={{
                  height: height,
                  maxHeight: height,
                  width: width,
                  overflowY: 'auto',
                }}
              >
                {flatData.map((item, index) => renderItem(item, index))}
                {flatData.length === 0 && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: height,
                    }}
                  >
                    <Texto>Hoy no hay bolos</Texto>
                  </Box>
                )}
              </Box>
            )}
          </AutoSizer>
        </Box>
      )}
    </Box>
  );
};
