import React, { useEffect, useState } from 'react';
import 'moment/locale/es';
import { RFPercentage } from '../../../../../utils/Functions.js';
import { useTema } from '../../../../../Context/TemaContext.js';
import { useData } from '../../../../../Context/DataContext.js';
import Texto from '../../../../Componentes/Texto.js';
import IconoJugador from '../../../../Componentes/IconoJugador.js';
import { Box } from '@mui/material';

const ClasificacionCampeonato = ({ tiradas, concurso }) => {
  const { buscarJugador, buscarBolera } = useData();
  const { tema } = useTema();

  const [clasificacion, setClasificacion] = useState([]);
  const [rondaActual, setRondaActual] = useState('');

  const [loading, setLoading] = useState(true);

  const cargarClasificacion = async () => {
    const jugadoresArray = [];

    const clasificacionArray = [];

    if (concurso.individual === true) {
      tiradas.forEach((data) => {
        if (data.tipo !== 'Desempate') {
          if (!jugadoresArray.includes(data.jugador1)) {
            jugadoresArray.push(data.jugador1);
          }
          if (!jugadoresArray.includes(data.jugador2)) {
            jugadoresArray.push(data.jugador2);
          }
        }
      });

      jugadoresArray.forEach((jugador) => {
        const tiradasJugador = tiradas.filter(
          (tirada) =>
            (tirada.jugador1 === jugador || tirada.jugador2 === jugador) &&
            tirada.tipo !== 'Desempate'
        );
        let total = 0;
        let octavos1 = 0;
        let octavos2 = 0;
        let cuartos = 0;
        let semifinales = 0;
        let final = 0;

        tiradasJugador.forEach((tirada) => {
          if (tirada.jugador1 === jugador) {
            const bolos = tirada.bolos1 === 'NP' ? 0 : tirada.bolos1;
            total = total + bolos;
            if (tirada.tipo === 'Octavos') {
              if (tirada.bolera === concurso.bolera) {
                octavos1 = tirada.bolos1;
              } else {
                octavos2 = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              cuartos = tirada.bolos1;
            }
            if (tirada.tipo === 'Semifinales') {
              semifinales = tirada.bolos1;
            }
            if (tirada.tipo === 'Final') {
              final = tirada.bolos1;
            }
          }
          if (tirada.jugador2 === jugador) {
            const bolos = tirada.bolos2 === 'NP' ? 0 : tirada.bolos2;
            total = total + bolos;
            if (tirada.tipo === 'Octavos') {
              if (tirada.bolera === concurso.bolera) {
                octavos1 = tirada.bolos2;
              } else {
                octavos2 = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              cuartos = tirada.bolos2;
            }
            if (tirada.tipo === 'Semifinales') {
              semifinales = tirada.bolos2;
            }
            if (tirada.tipo === 'Final') {
              final = tirada.bolos2;
            }
          }
        });

        if (jugador !== '') {
          clasificacionArray.push({
            jugador: jugador,
            total: total,
            octavos1: octavos1,
            octavos2: octavos2,
            cuartos: cuartos,
            semifinales: semifinales,
            final: final,
          });
        }
      });

      clasificacionArray.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        }
        if (a.total > b.total) {
          return -1;
        }
      });

      if (clasificacionArray.length > 0) {
        if (clasificacionArray[0].octavos1 === 0) {
          setRondaActual(16);
        } else if (clasificacionArray[0].cuartos === 0) {
          setRondaActual(8);
        } else if (clasificacionArray[0].semifinales === 0) {
          setRondaActual(4);
        } else {
          setRondaActual(2);
        }
      }
    } else {
      tiradas.forEach((data) => {
        if (data.tipo !== 'Desempate') {
          if (
            !jugadoresArray.find(
              (item) =>
                item[0] === data.jugadores1[0] && item[1] === data.jugadores1[1]
            )
          ) {
            jugadoresArray.push(data.jugadores1);
          }
          if (
            !jugadoresArray.find(
              (item) =>
                item[0] === data.jugadores2[0] && item[1] === data.jugadores2[1]
            )
          ) {
            jugadoresArray.push(data.jugadores2);
          }
        }
      });

      jugadoresArray.forEach((jugador) => {
        const tiradasJugador = tiradas.filter(
          (tirada) =>
            tirada.jugadores1[0] === jugador[0] ||
            tirada.jugadores2[0] === jugador[0]
        );
        let total = 0;
        let cuartos1 = 0;
        let cuartos2 = 0;
        let semifinales = 0;
        let final = 0;

        tiradasJugador.forEach((tirada) => {
          if (tirada.jugadores1[0] === jugador[0]) {
            const bolos = tirada.bolos1 === 'NP' ? 0 : tirada.bolos1;
            total = total + bolos;
            if (tirada.tipo === 'Cuartos') {
              if (tirada.bolera === concurso.bolera) {
                cuartos1 = tirada.bolos1;
              } else {
                cuartos2 = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              semifinales = tirada.bolos1;
            }
            if (tirada.tipo === 'Final') {
              final = tirada.bolos1;
            }
          }
          if (tirada.jugadores2[0] === jugador[0]) {
            const bolos = tirada.bolos2 === 'NP' ? 0 : tirada.bolos2;
            total = total + bolos;
            if (tirada.tipo === 'Cuartos') {
              if (tirada.bolera === concurso.bolera) {
                cuartos1 = tirada.bolos2;
              } else {
                cuartos2 = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              semifinales = tirada.bolos2;
            }
            if (tirada.tipo === 'Final') {
              final = tirada.bolos2;
            }
          }
        });

        if (jugador !== '') {
          clasificacionArray.push({
            jugador: `${jugador[0]} - ${jugador[1]}`,
            total: total,
            cuartos1: cuartos1,
            cuartos2: cuartos2,
            semifinales: semifinales,
            final: final,
          });
        }
      });

      clasificacionArray.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        }
        if (a.total > b.total) {
          return -1;
        }
      });

      if (clasificacionArray.length > 0) {
        if (clasificacionArray[0].cuartos1 === 0) {
          setRondaActual(8);
        } else if (clasificacionArray[0].semifinales === 0) {
          setRondaActual(4);
        } else {
          setRondaActual(2);
        }
      }
    }

    setClasificacion(clasificacionArray);

    setLoading(false);
  };

  useEffect(() => {
    try {
      cargarClasificacion();
    } catch (error) {
      console.log(error);
    }
  }, [concurso, tiradas]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 12,
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor:
            tema.id === 'Claro' ? 'rgba(0,0,0,0.07)' : 'rgba(255,255,255,0.07)',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Box style={{ flex: 1 }} />
        <Box
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Texto
            numberOfLines={1}
            style={{
              textAlign: 'center',
              fontSize: RFPercentage(1.2),
              flex: 1,
            }}
          >
            {concurso.bolera ? buscarBolera(concurso.bolera)?.nombre : ''}
          </Texto>
          <Texto
            numberOfLines={1}
            style={{
              textAlign: 'center',
              fontSize: RFPercentage(1.2),
              flex: 1,
            }}
          >
            {concurso.bolera2 ? buscarBolera(concurso.bolera2)?.nombre : ''}
          </Texto>
          {concurso.individual && (
            <Texto
              numberOfLines={1}
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(1.2),
                flex: 1,
              }}
            >
              Cuartos
            </Texto>
          )}

          <Texto
            numberOfLines={1}
            style={{
              textAlign: 'center',
              fontSize: RFPercentage(1.2),
              flex: 1,
            }}
          >
            Semifinales
          </Texto>
          <Texto
            style={{
              textAlign: 'center',
              fontSize: RFPercentage(1.2),
              flex: 1,
            }}
          >
            Final
          </Texto>
          <Texto
            style={{
              textAlign: 'center',
              fontSize: RFPercentage(1.2),
              flex: 1,
            }}
          >
            Total
          </Texto>
        </Box>
      </Box>
      {clasificacion.map((item, index) => (
        <Box
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderTop: `1px solid ${tema.button.default.borderColor}`,
            backgroundColor:
              index >= rondaActual && concurso.finalizado === false
                ? tema.id === 'Claro'
                  ? 'rgba(0, 0, 0, 0.1)'
                  : 'rgba(255, 255, 255, 0.1)'
                : tema.backgroundColor,
            alignItems: 'center',
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Texto numberOfLines={1} style={{ width: 25 }}>
              {index + 1}
            </Texto>
            {concurso.individual ? (
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <IconoJugador
                  route={'Competiciones'}
                  jugador={item.jugador}
                  size={'mediano'}
                />
                <Texto
                  numberOfLines={2}
                  style={{
                    maxWidth: '70%',
                    marginLeft: 5,
                    fontSize: RFPercentage(2),
                  }}
                >
                  {buscarJugador(item.jugador)?.nombre}
                </Texto>
              </Box>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                {item.jugador.split(' - ')?.map((j, index) => (
                  <Box
                    key={index}
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: index === 0 ? 4 : 0,
                    }}
                  >
                    <IconoJugador
                      route={'Competiciones'}
                      jugador={j}
                      size={'pequeño'}
                    />
                    <Texto
                      numberOfLines={2}
                      style={{
                        maxWidth: '70%',
                        marginLeft: 5,
                        fontSize: RFPercentage(1.8),
                      }}
                    >
                      {buscarJugador(j)?.nombre}
                    </Texto>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box
            style={{
              flex: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Texto
              numberOfLines={1}
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                flex: 1,
              }}
            >
              {concurso.individual ? item.octavos1 : item.cuartos1}
            </Texto>
            <Texto
              numberOfLines={1}
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                flex: 1,
              }}
            >
              {concurso.individual ? item.octavos2 : item.cuartos2}
            </Texto>
            {concurso.individual && (
              <Texto
                numberOfLines={1}
                style={{
                  textAlign: 'center',
                  fontSize: RFPercentage(2),
                  flex: 1,
                }}
              >
                {item.cuartos === 0 ? '0' : item.cuartos}
              </Texto>
            )}

            <Texto
              numberOfLines={1}
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                flex: 1,
              }}
            >
              {item.semifinales === 0 ? ' ' : item.semifinales}
            </Texto>
            <Texto
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                flex: 1,
              }}
            >
              {item.final === 0 ? ' ' : item.final}
            </Texto>
            <Texto
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                flex: 1,
                fontWeight: '600',
              }}
            >
              {item.total}
            </Texto>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ClasificacionCampeonato;
