import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Icon,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { getRef } from '../../../../database/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useData } from '../../../../Context/DataContext';
import { useLocation } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';
import PartidoComponente from '../../../Componentes/PartidoComponente';
import Clasificacion from '../../../Componentes/Clasificacion';
import { RFPercentage } from '../../../../utils/Functions';
import { useTema } from '../../../../Context/TemaContext';
import moment from 'moment';

const calculateScale = (componentHeight) => {
  const windowHeight = window.innerHeight;
  return windowHeight / componentHeight;
};

export default function Ligas() {
  const classes = useStyles();

  const { tema } = useTema();

  const location = useLocation();
  const { modalidad, ligaId } = location.state;

  const { ligas, buscarLiga } = useData();

  const [partidos, setPartidos] = useState([]);
  const [liga, setLiga] = useState(
    ligaId
      ? ligaId
      : ligas.sort((a, b) =>
          a.orden > b.orden ? 1 : a.orden < b.orden ? -1 : 0
        )[0]?.id
  );
  const [jornada, setJornada] = useState(null);
  const [jornadas, setJornadas] = useState([]);
  const [clasificacion, setClasificacion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [componentHeight, setComponentHeight] = useState(1000);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const suscribePartidos = async () => {
    const partidosRef = await getRef('partidos', [
      {
        type: 'where',
        param: 'liga',
        condition: '==',
        value: liga,
      },
      {
        type: 'where',
        param: 'jornada',
        condition: '==',
        value: jornada,
      },
    ]);

    const unsub = onSnapshot(partidosRef, (docsSnap) => {
      const partidosArray = [];

      docsSnap.forEach((doc) => {
        const data = doc.data();
        partidosArray.push({ ...data, id: doc.id });
      });

      setPartidos(partidosArray);
      setLoading(false);
    });
    return unsub;
  };

  useEffect(() => {
    suscribePartidos();
  }, [jornada, liga]);

  useEffect(() => {
    if (jornada === null && liga) {
      const l = buscarLiga(liga);
      setJornada(l.jornadaActual);
      setClasificacion(l.clasificacion);
      setComponentHeight(l.clasificacion.length * 80);
      let jor = [];
      for (let i = 0; i < l.jornadas; i++) {
        jor.push(i + 1);
      }
      setJornadas(jor);
      setTab(0);
    }
  }, [liga]);

  useEffect(() => {
    const newScale = calculateScale(componentHeight);
    setScale(newScale > 1.6 ? 1.6 : newScale);

    const handleResize = () => {
      const newScale = calculateScale(componentHeight);
      setScale(newScale > 1.6 ? 1.6 : newScale);
    };

    window.addEventListener('resize', handleResize);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentHeight]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: tema.backgroundColor,
      }}
    >
      <Box
        style={{
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '95%',
          margin: 12,
        }}
      >
        <Select
          value={liga}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          id='grouped-select'
          label='Ligas'
          style={{ fontSize: RFPercentage(2.2) }}
        >
          <Typography style={{ fontWeight: '600', marginLeft: 12 }}>
            {modalidad}
          </Typography>
          {ligas
            .filter(
              (l) => l.modalidad === modalidad && l.año === moment().year()
            )
            .map((l) => (
              <MenuItem
                onClick={() => {
                  setLiga(l.id);
                  setJornada(l.jornadaActual);
                  setClasificacion(l.clasificacion);
                  setComponentHeight(l.clasificacion.length * 80);
                  handleClose();
                }}
                style={{ paddingLeft: 24 }}
                value={l.id}
              >
                {l.nombre}
              </MenuItem>
            ))}
        </Select>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label='Jornadas' />
          <Tab label='Clasificación' />
        </Tabs>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-start',
        }}
      >
        {tab === 0 ? (
          <Box style={{ height: '100%' }}>
            <div
              style={{
                width: '100%',
                minHeight: 60,
              }}
            >
              {tab === 0 && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 12,
                  }}
                >
                  <Box
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant='contained'
                        style={{
                          backgroundColor: '#33db7a',
                          color: 'white',
                        }}
                        disabled={jornada === null || jornada === 1}
                        onClick={() => {
                          setJornada((prevState) => prevState - 1);
                        }}
                        startIcon={
                          <Icon>
                            <ArrowBackIcon />
                          </Icon>
                        }
                      >
                        Anterior
                      </Button>
                      <FormControl
                        className={classes.formControl}
                        style={{ marginLeft: 40, marginRight: 40 }}
                      >
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={jornada}
                          style={{ width: 120 }}
                          MenuProps={{ width: 120 }}
                          onChange={(event) => setJornada(event.target.value)}
                        >
                          {jornadas.map((i) => (
                            <MenuItem style={{ width: 120 }} value={i}>
                              Jornada {i.toString()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant='contained'
                        style={{
                          backgroundColor: '#33db7a',
                          color: 'white',
                        }}
                        disabled={jornadas[jornadas.length - 1] === jornada}
                        onClick={() => {
                          setJornada((prevState) => {
                            if (prevState === null) {
                              return 1;
                            } else {
                              return prevState + 1;
                            }
                          });
                        }}
                        endIcon={
                          <Icon>
                            <ArrowForwardIcon />
                          </Icon>
                        }
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </Box>
                </div>
              )}
            </div>
            {partidos &&
              partidos.length > 0 &&
              partidos
                .sort((a, b) => {
                  if (a.fecha > b.fecha) {
                    return 1;
                  } else if (a.fecha < b.fecha) {
                    return -1;
                  } else {
                    if (a.hora > b.hora) {
                      return 1;
                    }
                    if (a.hora < b.hora) {
                      return -1;
                    }
                    return 0;
                  }
                })
                .map((partido) => (
                  <PartidoComponente partido={partido} scale={scale} />
                ))}
          </Box>
        ) : tab === 1 ? (
          <Box style={{ flex: 1 }}>
            <Clasificacion
              cargandoClasificacion={loading}
              clasificacion={clasificacion}
              ligaId={liga}
              titulo={false}
              scale={scale}
            />
          </Box>
        ) : (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: window.innerHeight - 150,
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
