import React from 'react';
import { useTema } from '../../Context/TemaContext.js';
import { Typography } from '@material-ui/core';

const Texto = (props) => {
  const { children, style } = props;

  const { tema } = useTema();

  return (
    <Typography
      style={{
        color: tema.texto,
        ...style,
        ...(props.numberOfLines && {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: props.numberOfLines,
          WebkitBoxOrient: 'vertical',
        }),
      }}
    >
      {children}
    </Typography>
  );
};

export default Texto;
