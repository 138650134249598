import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { decryptData, encryptData } from '../utils/Functions';

const colors = {
  50: '#f0fdf5',
  100: '#dcfce9',
  200: '#bbf7d3',
  300: '#85f0b2',
  400: '#33db7a',
  500: '#21c668',
  600: '#15a452',
  700: '#148143',
  800: '#166539',
  900: '#145331',
  950: '#052e18',
};

const temaClaro = {
  id: 'Claro',
  texto: '#121212',
  cuadroDeTexto: 'white',
  primary: colors[400],
  secundary: colors[200],
  third: colors[800],
  backgroundColor: 'white',
  button: {
    default: {
      backgroundColor: [colors[400], colors[600]],
      textColor: '#121212',
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    hover: {
      backgroundColor: [colors[500], colors[700]],
      textColor: '#121212',
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    active: {
      backgroundColor: [colors[600], colors[800]],
      textColor: '#121212',
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    disabled: {
      backgroundColor: [colors[100], colors[300]],
      textColor: colors[700],
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  colors: colors,
};

const temaOscuro = {
  id: 'Oscuro',
  texto: 'white',
  cuadroDeTexto: 'rgba(255, 255, 255, 0.2)',
  primary: colors[400],
  secundary: colors[200],
  third: colors[400],
  backgroundColor: '#121212',
  button: {
    default: {
      backgroundColor: [colors[700], colors[500]],
      textColor: 'white',
      borderColor: 'white',
    },
    hover: {
      backgroundColor: [colors[600], colors[400]],
      textColor: 'white',
      borderColor: 'white',
    },
    active: {
      backgroundColor: [colors[500], colors[300]],
      textColor: 'white',
      borderColor: 'white',
    },
    disabled: {
      backgroundColor: [colors[800], colors[600]],
      textColor: colors[300],
      borderColor: 'white',
    },
  },
  colors: colors,
};

const initialState = {
  tema: temaClaro,
  temaLoading: true,
};

const configReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'guardar-tema':
      return {
        ...state,
        tema: payload.data,
        temaLoading: false,
      };
    case 'guardar-loading':
      return {
        ...state,
        temaLoading: payload.data,
      };
    default:
      return state;
  }
};

const TemaContext = createContext(initialState);

const TemaState = (props) => {
  const [state, dispatch] = useReducer(configReducer, initialState);

  const cambiarTema = async (datos) => {
    await localStorage.setItem('Tema', encryptData(datos));
    dispatch({
      type: 'guardar-tema',
      data: datos === 'Claro' ? temaClaro : temaOscuro,
    });
  };

  const cargarDatosIniciales = async () => {
    try {
      const fav = await localStorage.getItem('Tema');

      const favDecrypt = decryptData(fav);

      if (favDecrypt !== null) {
        cambiarTema(favDecrypt);
      } else {
        cambiarTema('Claro');
        dispatch({
          type: 'guardar-loading',
          data: false,
        });
      }
    } catch (error) {
      dispatch({
        type: 'guardar-loading',
        data: false,
      });
    }
  };

  useEffect(() => {
    cargarDatosIniciales();
  }, []);

  return (
    <TemaContext.Provider
      value={{
        tema: state.tema,
        temaLoading: state.temaLoading,
        cambiarTema,
      }}
    >
      {props.children}
    </TemaContext.Provider>
  );
};

const useTema = () => {
  return useContext(TemaContext);
};

export { TemaState, TemaContext, useTema };
