import requireTv from '../../database/requireTv';
import { memo } from 'react';
import { useData } from '../../Context/DataContext';
import { Box } from '@material-ui/core';
import Texto from '../Componentes/Texto';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../Router/routes';

const HeaderItem = memo(({ item }) => {
  const { buscarConcurso, categoriasFix, buscarCompeticion, buscarCategoria } =
    useData();

  let history = useHistory();

  if (item.liga !== undefined) {
    return (
      <Box
        key={item.titulo}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        }}
        onClick={() => {
          if (item.liga.tipo !== 'amistoso') {
            const competicion = buscarCompeticion(item.id);
            if (item.liga.clasificacion === undefined) {
              history.push(ROUTES.copas, {
                modalidad: competicion.modalidad,
                copaId: item.id,
              });
            } else {
              history.push(ROUTES.ligas, {
                modalidad: competicion.modalidad,
                ligaId: item.id,
              });
            }
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderTopWidth: 1,
            borderTopColor: 'rgba(0, 0, 0, 0.04)',
            position: 'relative',
          }}
        >
          <Texto
            style={{
              fontWeight: '600',
              paddingVertical: 2,
              textAlign: 'center',
              paddingHorizontal: 4,
            }}
            numberOfLines={1}
          >
            {item.titulo}
          </Texto>
        </Box>
      </Box>
    );
  } else {
    const concurso = buscarConcurso(item.id);
    const categoria = buscarCategoria(concurso.categorías[0]);

    return (
      <Box
        key={item.titulo}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        }}
        onClick={() => {
          if (concurso.tipo === 'Clasificación' || concurso.tipo === 'Final') {
            history.push(ROUTES.concursos, {
              modalidad: categoria.modalidad,
              tipos: ['Clasificación', 'Final'],
              concursoId: concurso.id,
            });
          } else {
            history.push(ROUTES.campeonatos, {
              modalidad: categoria.modalidad,
              tipos: ['Campeonato Regional', 'Campeonato de España'],
              concursoId: concurso.id,
            });
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              style={{
                fontWeight: '600',
                paddingVertical: 2,
                textAlign: 'center',
                paddingHorizontal: 4,
                flex: 1,
              }}
            >
              {!concurso.categorías
                ? '.'
                : concurso.tipo === 'Final'
                ? `Final - ${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : concurso.tipo === 'Clasificacion'
                ? `${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : `${concurso.tipo} ${
                    concurso.individual ? 'Individual' : 'por parejas'
                  } ${categoriasFix(concurso.categorías)} - ${
                    concurso.modalidad
                  }`}
            </Texto>
            {concurso.televisiones !== undefined &&
              concurso.televisiones.map((item, index) => (
                <img
                  key={index}
                  src={requireTv(item.televisión)}
                  style={{
                    width: 17,
                    height: 17,
                    borderRadius: 60,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                  cachePolicy={'memory-disk'}
                />
              ))}
          </Box>
        </Box>
      </Box>
    );
  }
});

export default HeaderItem;
