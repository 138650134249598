import { TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const CuadroDeTexto = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChangeState = () => {
    return props.onChange(value ?? '');
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (props.debounceable) {
      setValue(inputValue);
    } else {
      props.onChange(inputValue);
    }
  };

  useEffect(() => {
    if (props.debounceable === true && props.value !== value) {
      const timeoutId = setTimeout(() => {
        handleChangeState();
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  return (
    <TextField
      label={props.label}
      InputLabelProps={{ shrink: props.labelUp }}
      name={props.name}
      value={props.debounceable ? value : props.value}
      onChange={handleChange}
      fullWidth={props.fullWidth ?? false}
      required={props.required ?? false}
      autoFocus={props.autoFocus ?? false}
      variant={props.variant ?? 'outlined'}
      InputProps={{
        readOnly: props.readonly ?? false,
        style: {
          fontSize: props.fontSize ?? '14px',
          borderRadius: 12,
        },
        startAdornment: props.startIcon ?? null,
        endAdornment: props.endIcon ?? null,
      }}
      inputProps={{
        maxLength: props.maxLength,
        style: {
          textAlign: props.textAlign ?? 'left',
        },
      }}
      disabled={props.disabled}
    />
  );
};
