import { Box, CircularProgress, Divider } from '@material-ui/core';
import { useTema } from '../../Context/TemaContext';
import { RFPercentage, innerWidth } from '../../utils/Functions';
import Texto from './Texto';
import IconoPeña from './IconoPena';
import { useData } from '../../Context/DataContext';
import { useUsuario } from '../../Context/UsuarioContext';
import { useMemo } from 'react';
import { Loader } from './Loader';
import Ronda from './Ronda';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const Brakets = ({ tabLabel, copa, route }) => {
  const { tema } = useTema();

  return (
    <Box
      style={{
        flex: 1,
        backgroundColor: tema.backgroundColor,
        minWidth: innerWidth,
      }}
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: 5,
        }}
      >
        <Texto style={{ fontWeight: 'bold' }}>{tabLabel.toUpperCase()}</Texto>
      </Box>
      {copa.fases[tabLabel.toLowerCase()]
        .sort(function (a, b) {
          if (a.posicion > b.posicion) {
            return 1;
          }
          if (a.posicion < b.posicion) {
            return -1;
          }
          return 0;
        })
        .map((partido, index) => {
          return (
            <Box key={index}>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    width: '95%',
                    borderRadius: 6,
                    marginBottom: 10,
                  }}
                >
                  <Box style={{ flexDirection: 'row' }}>
                    <Box style={{ flex: 1 }}>
                      <Box style={{ flexDirection: 'row', height: 50 }}>
                        <Box
                          style={{
                            paddingRight: 10,
                            paddingLeft: 10,
                            width: 70,
                          }}
                        >
                          <Box style={{ paddingBottom: 5, paddingTop: 5 }}>
                            <IconoPeña
                              route={route}
                              peñaId={partido.local}
                              size={40}
                            />
                          </Box>
                        </Box>
                        <Box
                          style={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flex: 1,
                            marginLeft: 0,
                          }}
                        >
                          <Texto
                            numberOfLines={1}
                            style={{
                              marginBottom: 5,
                              fontSize: 15,
                              maxWidth: '80%',
                            }}
                          >
                            {partido.local}
                          </Texto>
                        </Box>
                      </Box>
                      <Box style={{ flexDirection: 'row', height: 50 }}>
                        <Box
                          style={{
                            paddingRight: 10,
                            paddingLeft: 10,
                            width: 70,
                          }}
                        >
                          <Box style={{ paddingBottom: 5 }}>
                            <IconoPeña
                              route={route}
                              peñaId={partido.visitante}
                              size={40}
                            />
                          </Box>
                        </Box>
                        <Box
                          style={{
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flex: 1,
                            marginLeft: 0,
                          }}
                        >
                          <Texto
                            numberOfLines={1}
                            style={{
                              marginBottom: 5,
                              fontSize: 15,
                              maxWidth: '80%',
                            }}
                          >
                            {partido.visitante}
                          </Texto>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        width: 0.5,
                        backgroundColor: 'grey',
                        height: '100%',
                      }}
                    />
                    <Box style={{ alignItems: 'center', width: 30 }}>
                      <Box style={{ paddingTop: 10 }}>
                        <Texto
                          style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                          }}
                        >
                          {partido.ch_l}
                        </Texto>
                      </Box>
                      <Box style={{ paddingTop: 20 }}>
                        <Texto
                          style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                          }}
                        >
                          {partido.ch_v}
                        </Texto>
                      </Box>
                    </Box>
                    {copa.partidoDoble === true && (
                      <Box
                        style={{
                          width: 0.5,
                          backgroundColor: 'grey',
                          height: '100%',
                        }}
                      />
                    )}
                    {copa.partidoDoble === true && (
                      <Box style={{ alignItems: 'center', width: 30 }}>
                        <Box style={{ paddingTop: 10 }}>
                          <Texto
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                            }}
                          >
                            {partido.ch_l2}
                          </Texto>
                        </Box>
                        <Box style={{ paddingTop: 20 }}>
                          <Texto
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                            }}
                          >
                            {partido.ch_v2}
                          </Texto>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

const Clasificacion = (props) => {
  const {
    partidoState,
    clasificacion = [],
    cargandoClasificacion = false,
    partidos = [],
    ligaId,
    route = 'Inicio',
    actualizado = false,
    titulo = null,
    scale = 1,
  } = props;

  const { buscarPeña, buscarLiga, buscarCopa } = useData();
  const { usuario } = useUsuario();
  const { tema } = useTema();
  const liga = useMemo(() => buscarLiga(ligaId), [ligaId]);

  const copa = useMemo(() => buscarCopa(ligaId), [ligaId]);

  const clasOrdenada = useMemo(() => {
    if (actualizado) {
      return clasificacion.sort((a, b) => {
        if (a.puntos !== b.puntos) {
          return b.puntos - a.puntos;
        } else if (a.dif !== b.dif) {
          return b.dif - a.dif;
        } else return 1;
      });
    } else {
      return clasificacion;
    }
  }, [ligaId]);

  if (cargandoClasificacion === true) {
    return <Loader />;
  } else if (copa && copa.id !== '') {
    if (copa.rondas) {
      return (
        <AutoSizer>
          {({ height, width }) => (
            <Box
              height={height}
              width={width}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TransformWrapper
                limitToBounds={false}
                maxScale={2}
                minScale={0.5}
              >
                <TransformComponent>
                  <Box
                    style={{
                      borderRadius: 20,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    {copa.rondas.map((item, index) => (
                      <Ronda
                        key={index}
                        ronda={item}
                        partidos={partidos}
                        nRondas={copa.rondas.length}
                        copa={copa}
                        habilitado={
                          usuario.permisos && usuario.permisos.includes('Admin')
                        }
                      />
                    ))}
                  </Box>
                </TransformComponent>
              </TransformWrapper>
            </Box>
          )}
        </AutoSizer>
      );
    } else {
      return (
        <Box style={{ flex: 1, marginBottom: 20 }}>
          <Box>
            {copa.fases.hasOwnProperty('dieciseisavos') && (
              <Brakets tabLabel='Dieciseisavos' copa={copa} route={route} />
            )}
            {copa.fases.hasOwnProperty('octavos') && (
              <Brakets tabLabel='Octavos' copa={copa} route={route} />
            )}
            {copa.fases.hasOwnProperty('cuartos') && (
              <Brakets tabLabel='Cuartos' copa={copa} route={route} />
            )}
            {copa.fases.hasOwnProperty('semifinal') && (
              <Brakets tabLabel='Semifinal copa={copa}' route={route} />
            )}
            {copa.fases.hasOwnProperty('final') && (
              <Brakets tabLabel='Final' copa={copa} route={route} />
            )}
            {copa.fases.length === 0 && (
              <Box>
                <Texto style={{ paddingTop: 20, textAlign: 'center' }}>
                  Aún no hay partidos
                </Texto>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  } else {
    return (
      <Box style={{ flex: 1 }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            {titulo && (
              <Texto
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontSize: RFPercentage(1.4) * scale,
                  fontWeight: 'bold',
                }}
              >
                {buscarLiga(ligaId).nombre}
              </Texto>
            )}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 20,
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              borderBottomWidth: 1,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              flex: 1,
            }}
          >
            {['J', 'G', 'E', 'P', 'CH_F', 'CH_C', 'DIF', 'PTOS'].map((i) => (
              <Texto
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontSize: 10,
                  fontWeight: 'bold',
                }}
              >
                {i}
              </Texto>
            ))}
            <Divider orientation='horizontal' />
          </Box>
        </Box>
        <Box style={{ flex: 1 }}>
          <Divider orientation='horizontal' />
          {clasOrdenada.map((clas, index) => {
            const peña = buscarPeña(clas.nombre);
            let colorPosicion = 'transparent';
            const colores = liga.colores || [];
            const posicion = clas.posicion ? clas.posicion : index + 1;
            const color = colores.find((i) => i.posicion === posicion);
            if (color !== undefined) {
              colorPosicion = color.color;
            }

            let ocultarPosición =
              clasOrdenada[index - 1] &&
              clasOrdenada[index - 1].puntos === clas.puntos &&
              clasOrdenada[index - 1].dif === clas.dif;

            const equipoSinActualizar =
              liga.clasificacion.find((item) => item.nombre === clas.nombre) ||
              {};

            return (
              <Box key={clas.nombre} style={{ height: 55 * scale }}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderLeftWidth: 5,
                    borderLeftColor: colorPosicion,
                    alignItems: 'center',
                    backgroundColor:
                      partidoState &&
                      (clas.nombre === partidoState.local ||
                        clas.nombre === partidoState.visitante)
                        ? 'rgba(255,255,255,0.5)'
                        : 'transparent',
                    marginTop: 4 * scale,
                    marginBottom: 4 * scale,
                  }}
                >
                  <Box
                    style={{
                      flex: 1,
                      paddingLeft: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: 30,
                        alignItems: 'center',
                      }}
                    >
                      <Texto
                        style={{
                          textAlign: 'center',
                          fontSize: RFPercentage(1.6) * scale,
                        }}
                      >
                        {!ocultarPosición ? posicion : '  '}
                      </Texto>
                    </Box>
                    <IconoPeña
                      route={route}
                      peñaId={clas.nombre}
                      size={45 * scale}
                    />
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        marginLeft: 12,
                      }}
                    >
                      <Texto
                        numberOfLines={1}
                        style={{
                          fontWeight: '500',
                          fontSize: RFPercentage(2) * scale,
                        }}
                      >
                        {peña.nombre || ''}
                      </Texto>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {clas.ultimosResultados !== undefined &&
                          clas.ultimosResultados.map((item, index) => {
                            return (
                              <Box
                                key={index}
                                style={{
                                  backgroundColor:
                                    item === 'V'
                                      ? 'rgba(0, 255, 0, 0.5)'
                                      : item === 'P'
                                      ? 'rgba(255, 0, 0, 0.5)'
                                      : item === '-'
                                      ? 'rgba(0, 0, 0, 0.1)'
                                      : 'rgba(255, 255, 0, 0.5)',
                                  borderRadius: 2,
                                  marginRight: 6 * scale,
                                  paddingLeft: 6 * scale,
                                  paddingRight: 6 * scale,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Texto
                                  style={{
                                    fontSize: RFPercentage(1) * scale,
                                    textAlign: 'center',
                                    paddingHorizontal: 2,
                                    paddingVertical: 1,
                                  }}
                                >
                                  {item}
                                </Texto>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor:
                        partidoState &&
                        (clas.nombre === partidoState.local ||
                          clas.nombre === partidoState.visitante)
                          ? 'rgba(255,255,255,0.5)'
                          : 'transparent',
                    }}
                  >
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.jugados === clas.jugados ||
                          !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.jugados}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.ganados === clas.ganados ||
                          !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.ganados}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.empatados === clas.empatados ||
                          !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.empatados}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.perdidos === clas.perdidos ||
                          !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.perdidos}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.ch_f === clas.ch_f || !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.ch_f}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.ch_c === clas.ch_c || !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.ch_c}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        color:
                          equipoSinActualizar.dif === clas.dif || !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.dif}
                    </Texto>
                    <Texto
                      style={{
                        flex: 1,
                        fontSize: RFPercentage(2) * scale,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color:
                          equipoSinActualizar.dif === clas.dif || !actualizado
                            ? tema.texto
                            : 'rgba(33, 176, 60, 0.7)',
                      }}
                    >
                      {clas.puntos}
                    </Texto>
                  </Box>
                </Box>
                <Divider orientation='horizontal' />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
};

export default Clasificacion;
