import { useEffect, useRef, useState } from 'react';
import { useTema } from '../../Context/TemaContext';
import { Box, Dialog, Paper } from '@material-ui/core';
import moment from 'moment';
import Texto from './Texto';
import { RFPercentage } from '../../utils/Functions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateCalendar } from '@mui/x-date-pickers';

const Fechas = ({ fechaSeleccionada, setFechaSeleccionada, setLoading }) => {
  const { tema } = useTema();
  const [dias, setDias] = useState([]);
  const [visible, setVisible] = useState(false);

  const scrollRef = useRef(null);

  const cargarDias = (dia) => {
    const startOfWeek = moment(dia).startOf('isoWeek');
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.clone().add(i, 'days'));
    }

    setDias(['mas', ...days.map((d) => d.format('YYYY-MM-DD')), 'mas']);
  };

  useEffect(() => {
    cargarDias(moment(fechaSeleccionada).format('YYYY-MM-DD'));
  }, [fechaSeleccionada]);

  const onPress = (fecha) => {
    if (fecha === 'mas') {
      setVisible(true);
    } else {
      if (fecha !== fechaSeleccionada) {
        setLoading(true);
        setFechaSeleccionada(fecha);
      }
    }
  };

  // useEffect(() => {
  //   const fechaCentroIndex = Math.floor(dias.length / 2);

  //   // Calcula la posición del elemento correspondiente al centro
  //   const centerElement = document.getElementById(fechaSeleccionada);
  //   if (centerElement && scrollRef.current) {
  //     const container = scrollRef.current;
  //     const centerElementRect = centerElement.getBoundingClientRect();
  //     const containerRect = container.getBoundingClientRect();
  //     const containerScrollLeft = container.scrollLeft;
  //     const elementOffsetLeft =
  //       centerElementRect.left - containerRect.left + containerScrollLeft;
  //     const elementOffsetRight =
  //       centerElementRect.right - containerRect.right + containerScrollLeft;
  //     const isElementInLeftView = elementOffsetLeft < containerRect.width / 2;
  //     const isElementInRightView = elementOffsetRight > containerRect.width / 2;

  //     let scrollLeft = containerScrollLeft;
  //     if (!isElementInLeftView && isElementInRightView) {
  //       scrollLeft = Math.max(0, elementOffsetRight - containerRect.width / 2);
  //     } else if (!isElementInRightView && isElementInLeftView) {
  //       scrollLeft = Math.max(0, elementOffsetLeft - containerRect.width / 2);
  //     }

  //     container.scrollTo({
  //       left: scrollLeft,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [dias, fechaSeleccionada]);

  return (
    <Box
      ref={scrollRef}
      id='contenedor-scroll'
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'hidden',
        overflowY: 'hidden',
        justifyContent: 'center',
      }}
    >
      <Dialog
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={visible}
        onClose={() => setVisible(false)}
      >
        <Box
          style={{
            padding: 12,
            backgroundColor: tema.backgroundColor,
            borderRadius: 12,
          }}
        >
          {/* <Box
            style={{
              height: 40,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box onClick={() => setVisible(null)}>
              <CloseIcon
                style={{
                  height: 30,
                  width: 30,
                }}
              />
            </Box>
          </Box> */}
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateCalendar
              value={moment(fechaSeleccionada)}
              onChange={(newValue) => {
                setFechaSeleccionada(moment(newValue).format('YYYY-MM-DD'));
                setVisible(null);
              }}
              showDaysOutsideCurrentMonth
              displayWeekNumber
              container='inline'
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Dialog>
      {dias.map((item, index) => {
        return (
          <Box
            onClick={() => onPress(item)}
            key={index}
            id={item}
            style={{
              flex: index === 0 || index === dias.length - 1 ? 0.5 : 1,
              borderRadius: 12,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: item === 'mas' ? 'center' : 'space-between',
              alignItems: 'center',
              padding: 6,
              margin: 12,
              marginLeft: window.innerWidth < 600 ? 3 : 7,
              marginRight: window.innerWidth < 600 ? 3 : 7,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              backgroundColor:
                fechaSeleccionada === item
                  ? tema.primary
                  : tema.backgroundColor,
              cursor: 'pointer',
            }}
          >
            {item === 'mas' ? (
              <>
                <Texto
                  style={{
                    fontSize: RFPercentage(1),
                    fontWeight: '500',
                    maxWidth: '90%',
                    textAlign: 'center',
                    color:
                      fechaSeleccionada !== item &&
                      item === moment().format('YYYY-MM-DD')
                        ? tema.primary
                        : tema.texto,
                  }}
                ></Texto>
                <Texto
                  style={{
                    fontSize: RFPercentage(2.4),
                    fontWeight: '500',
                    textAlign: 'center',
                    color: tema.texto,
                  }}
                >
                  {index === 0 ? '<' : '>'}
                </Texto>
                <Texto
                  style={{
                    fontSize: RFPercentage(0.8),
                    fontWeight: '400',
                    maxWidth: '90%',
                    textAlign: 'center',
                    color:
                      fechaSeleccionada !== item &&
                      item === moment().format('YYYY-MM-DD')
                        ? tema.primary
                        : tema.texto,
                  }}
                ></Texto>
              </>
            ) : (
              <>
                <Texto
                  numberOfLines={4}
                  style={{
                    fontSize: RFPercentage(1.6),
                    fontWeight: '500',
                    maxWidth: '90%',
                    textAlign: 'center',
                    color:
                      fechaSeleccionada !== item &&
                      item === moment().format('YYYY-MM-DD')
                        ? tema.primary
                        : tema.texto,
                  }}
                >
                  {moment(item).format('MMM')}
                </Texto>
                <Texto
                  numberOfLines={4}
                  style={{
                    fontSize: RFPercentage(2.8),
                    fontWeight: '600',
                    maxWidth: '90%',
                    textAlign: 'center',
                    color:
                      fechaSeleccionada !== item &&
                      item === moment().format('YYYY-MM-DD')
                        ? tema.primary
                        : tema.texto,
                  }}
                >
                  {moment(item).format('DD')}
                </Texto>
                <Texto
                  numberOfLines={4}
                  style={{
                    fontSize: RFPercentage(1.2),
                    fontWeight: '400',
                    maxWidth: '90%',
                    textAlign: 'center',
                    color:
                      fechaSeleccionada !== item &&
                      item === moment().format('YYYY-MM-DD')
                        ? tema.primary
                        : tema.texto,
                  }}
                >
                  {moment(item).format('ddd')}
                </Texto>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default Fechas;
