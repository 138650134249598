import React, { useMemo } from 'react';
import IconoPeña from './IconoPena.js';
import IconoJugador from './IconoJugador.js';
import requireTv from '../../database/requireTv.js';
import PersonIcon from '@material-ui/icons/Person';
import { Avatar } from '@material-ui/core';
import { useData } from '../../Context/DataContext.js';

const AvatarUsuario = ({ usuario, size = 40 }) => {
  const { jugadores, buscarPeña } = useData();

  const jugador = useMemo(
    () =>
      jugadores &&
      jugadores.find(
        (item) =>
          item.usuario !== undefined && item.usuario === usuario.nombreUsuario
      ) !== undefined
        ? jugadores.find(
            (item) =>
              item.usuario !== undefined &&
              item.usuario === usuario.nombreUsuario
          )
        : undefined,
    [jugadores, usuario]
  );

  const peña = useMemo(
    () =>
      usuario.favorito && usuario.favorito !== ''
        ? buscarPeña(usuario.favorito)
        : undefined,
    [usuario]
  );

  if (jugador) {
    return (
      <IconoJugador
        jugador={jugador.id}
        peña={jugador.peña}
        size={
          size >= 30 && size <= 40
            ? 'pequeño'
            : size < 30
            ? 'enano'
            : size > 100
            ? 'grande'
            : 'mediano'
        }
        style={{ zIndex: -1, paddingLeft: 3, paddingRight: 2 }}
        paddingHorizontal={0}
        disabled
      />
    );
  } else if (
    usuario.medio &&
    usuario.permisos &&
    usuario.permisos.includes('Prensa')
  ) {
    return (
      <img
        style={{
          width: size - 6,
          height: size - 6,
          borderRadius: 60,
          borderColor: 'black',
          backgroundColor: 'transparent',
        }}
        src={requireTv(usuario.medio)}
      />
    );
  } else if (peña) {
    return <IconoPeña peñaId={usuario.favorito} size={size + 4} disabled />;
  } else {
    return (
      <Avatar
        style={{
          height: size,
          width: size,
        }}
      >
        <PersonIcon />
      </Avatar>
    );
  }
};

export default AvatarUsuario;
